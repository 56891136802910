/**
 * Toggle Mobile Navigation
 *
 * @type {{init}}
 */
const ToggleNav = (() => {
    const DOM = {};
    const media = window.matchMedia("(max-width: 1200px)");

    const cacheDOM = () => {
        DOM.toggleNav = document.getElementsByClassName("header__toggle-nav")[0];
        DOM.navigation = document.getElementsByClassName("header__navigation")[0];
        DOM.subMenu = document.querySelectorAll(".menu-item-has-children");
    };

    const toggle = event => {
        event.preventDefault();
        handleMenuItemClick(DOM.toggleNav, 'header__toggle-nav--open', event);

        if (DOM.toggleNav.classList.contains("header__toggle-nav--open")) {

            DOM.toggleNav.classList.replace(
                "header__toggle-nav--open",
                "header__toggle-nav--closed"
            );
            DOM.navigation.classList.remove("header__navigation--open");
            DOM.navigation.style.height = DOM.navigation.clientHeight + 'px';

            setTimeout(function () {
                DOM.navigation.style.height = "0px";
            }, 0);
        } else {
            DOM.toggleNav.classList.replace(
                "header__toggle-nav--closed",
                "header__toggle-nav--open"
            );
            DOM.navigation.classList.add("header__navigation--open");
            toggleAnimation(DOM.navigation);
        }
    };

    const openSubMenu = event => {
        const subMenuParent = event.currentTarget;
        const subMenu = event.currentTarget.firstElementChild.nextElementSibling;

        handleMenuItemClick(subMenuParent, 'open', event);

        if (subMenuParent.classList.contains("open")) {
            subMenuParent.classList.remove("open");
            subMenu.classList.remove("open");
            subMenu.style.height = "0px";
            DOM.navigation.style.height = "auto";
        } else {
            subMenuParent.classList.add("open");
            subMenu.classList.add("open");
            toggleAnimation(subMenu);
            DOM.navigation.style.height = "auto";
        }
    };

    const handleMenuItemClick = (element, elementClass, event) => {
        if (!element.classList.contains(elementClass)) {
            event.preventDefault();
        }
    };

    const toggleAnimation = target => {
        target.style.height = 'auto';
        const height = target.clientHeight + 'px';
        target.style.height = '0px';

        setTimeout(function () {
            target.style.height = height;
        }, 0);
    };

    const eventListeners = () => {
        DOM.toggleNav.addEventListener("click", toggle);

        Array.from(DOM.subMenu).forEach(HTMLNode => {
            if (media.matches) {
                HTMLNode.addEventListener("click", openSubMenu);
            } else {
                HTMLNode.addEventListener("mouseover", openSubMenu);
                HTMLNode.addEventListener("mouseout", openSubMenu);
            }

            media.addListener(function() {
                if (media.matches) {
                    HTMLNode.addEventListener("click", openSubMenu);

                    HTMLNode.removeEventListener("mouseover", openSubMenu);
                    HTMLNode.removeEventListener("mouseout", openSubMenu);
                } else {
                    HTMLNode.removeEventListener("click", openSubMenu);

                    HTMLNode.addEventListener("mouseover", openSubMenu);
                    HTMLNode.addEventListener("mouseout", openSubMenu);
                }
            });
        });
    };

    const init = () => {
        cacheDOM();

        if (DOM.toggleNav) {
            eventListeners();
        }
    };

    return {
        init
    };
})();

export default ToggleNav;
