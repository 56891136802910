const SkipLinks = (() => {
    const DOM = {};

    const cacheDOM = () => {
        DOM.skipLinks = document.getElementsByClassName('skip-links');
    };

    const focusLinks = (e) => {
        console.log(e);
        e.currentTarget.classList.remove('hidden');
    };

    const focusOutLinks = (e) => {
        e.currentTarget.classList.add('hidden');
    };

    const addEventListeners = () => {
        DOM.skipLinks[0].addEventListener('focusin', focusLinks);
        DOM.skipLinks[0].addEventListener('focusout', focusOutLinks);
    };

    const init = () => {
        cacheDOM();
        addEventListeners();
    };

    return {
        init
    }
})();

export default SkipLinks;
