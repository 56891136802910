const ScrollToElement = (() => {
    const DOM = {};

    const cacheDOM = () => {
        DOM.errorMessageEl = document.getElementsByClassName('error-message')[0];
        DOM.formContainer = document.getElementsByClassName('form');
    };

    const Scroll = ScrollElement => {
        ScrollElement.classList.add('active');
        ScrollElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };

    const getElement = element => {
        if (document.body.classList.contains('home')) {
            const targetURL = 'login';
            const ScrollElement = DOM.formContainer[0].children[targetURL];
            Scroll(ScrollElement);
        }
    };

    const checkErrorMessage = () => {
        if ( DOM.errorMessageEl ) {
            getElement();
        }
    };

    const init = () => {
        cacheDOM();
        checkErrorMessage();
    };

    return  {
        init
    };
})();

export default ScrollToElement;
