import "core-js/stable";
import "regenerator-runtime/runtime";
import ToggleNav from "./ToggleNav";
import Filter from "./Filter";
import ScrollToElement from "./ScrollToElement";
import SkipLinks from "./SkipLinks";

(() => {
    ToggleNav.init();
    Filter.init();
    ScrollToElement.init();
    SkipLinks.init();
})();
