import mixitup from 'mixitup';

const Filter = (() => {
    const DOM = {};

    const cacheDOM = () => {
        DOM.listItems = document.getElementsByClassName('case-studies__filter');
        DOM.allimages = document.getElementsByClassName('case-studies__item ');
    };

    const filterElements = (element) => {
        const container = document.getElementById('case-studies');
        const filterKey = element ? element.currentTarget.getAttribute('data-filter') : 'all';

        const mixer = mixitup(container, {
            selectors: {
                target: '.case-studies__item'
            },
            animation: {
                duration: 400,
                // effects: 'fade `stagger(34ms) scale(0.5)',
                easing: 'ease'
            }
        });

        if (filterKey === 'all') {
            mixer.filter('all');
        } else {
            mixer.filter(filterKey);
        }
    };

    const eventListener = () => {
        Array.from(DOM.listItems).forEach(element => {
            element.addEventListener("click", filterElements);
        });
    };

    const init = () => {
        cacheDOM();
        eventListener();
    };

    return {
        init
    };
})();

export default Filter;
